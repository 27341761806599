import React from 'react';
import Typography from '@material-ui/core/Typography';
import NumberFormat from 'react-number-format';

import Title from './Title';


function Counter(props) {

  return (
    <React.Fragment>
      <Title>現在の area_id</Title>
      <Typography component="h2" variant="h2" color="textSecondary" gutterBottom >
      <NumberFormat value={props.count} displayType={'text'} thousandSeparator={true} />
      </Typography>
    </React.Fragment>
  );
}

export default (Counter);