
class User {
  isLoggedIn = () => this.get('isLoggedIn') === 'true';

  getToken = () => {
    return this.get('token');
  };

  set = (key, value) => localStorage.setItem(key, value);

  get = key => this.getLocalStorage(key);

  getLocalStorage = key => {
    const ret = localStorage.getItem(key);
    if (ret) {
      return ret;
    }
    return null;
  };

  login = (token) => {
    if (!this.isLoggedIn()) {
      this.set('isLoggedIn', true);
      this.set('token', token);
    }
  };

  logout =  () => {
    if (this.isLoggedIn()) {
      this.set('isLoggedIn', false);
      this.set('token', '');
    }
  };


}

export default new User();