import React, {useState, useEffect, createContext, useContext} from 'react';
import { withRouter, useParams, useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl } from '@material-ui/core';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import SendIcon from '@material-ui/icons/Send';
import DeleteIcon from '@material-ui/icons/Delete';
import AddLocationIcon from '@material-ui/icons/AddLocation';

import PauseIcon from '@material-ui/icons/Pause';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from '@material-ui/core/IconButton';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


import User from './User';

import Title from './Title';
import MapContainer from './MapContainerApi';
//import MapContainer from './MapContainer';

const dialogTitle = "喫煙所マップ管理画面";
const confMessage =  {
  reg: '登録しますか？',
  del: '削除しますか？',
}
const compMessage =  {
  reg: '登録しました',
  del: '削除しました',
}

function createData(id, lat, lng, title, summary01, rating ) {
  return { id, lat, lng, title, summary01, rating  };
}

function preventDefault(event) {
  event.preventDefault();
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  formControl: {
    width:'100%',
    padding:theme.spacing(2),
    paddingTop:theme.spacing(2),
  },
  textField: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  header: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '0',
    paddingBottom: '0',
    fontSize: '12px',
  },
  map: {
    maxWidth: '100%',
  },
  media: {
    minHeight: '280px',
    height: `calc(100vh - 300px)`,
  },
  button: {
    margin: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function ReportChecker(props) {
  const classes = useStyles();
  const history = useHistory();


  const [sendOpen, setSendOpen] = useState(false);
  const [backdropOpen, setBackdropOpen] = useState(false);

  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const [snackDuration, setSnackDuration] = useState(null);

  const [arounds, setArounds] = useState([]);
  const [mode, setMode] = useState('');

  const [sendButtonDisabled, setSendButtonDisabled] = useState(false);
  const [delButtonDisabled, setDelButtonDisabled] = useState(false);

  const [detailHeight, setDetailHeight] = useState(0);


  const distance = 500; // 500m
  const default_rating_status = 4;
  const default_official = 0;

  const onChangeText = (e) => {
      props.setReport({
        ...props.report,
        [e.target.name]: e.target.value,
      });
  }

  const handleSendButtonClick = (command) => {

    if(!command || command==='') {
      return;
    }
    setMode(command);
    setSendOpen(true);
  }

  const handleSend = () => {
    setBackdropOpen(true);
    postArea(mode);
    setSendOpen(false);
  };

  const handleClose = () => {
    setSendOpen(false);
  };

  const handleSnackOpen = ( severity, message) => {
    if ( severity === 'success' ) {
      setSnackDuration(3000);
    } else {
      setSnackDuration(null);
    }
    setSeverity(severity);
    setSnackMessage(message);
    setSnackOpen(true);
  }


  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackOpen(false);
  };

  const setButtonDisable = (flg) => {
    setSendButtonDisabled(flg);
    setDelButtonDisabled(flg);
  }

  const dateFormat = (timestamp) => {
    return new Date(timestamp).toLocaleString();
  }

  const postArea = async (mode) => {
    const url = process.env.REACT_APP_API_URL+'/post_area'
    const res = await axios ( {
      method: 'POST',
      url: url,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+User.getToken(),
      },
      params: {
        mode: mode,
      },
      data: {
        mode: mode,
        area_id: props.report.id,
        lat: props.report.lat,
        lng: props.report.lng,
        title: props.report.title,
        rating_status: default_rating_status, // 4
        description: props.report.description,
        summary01: props.report.summary01,
        reporter: props.report.reporter,
        official: default_official, // 0
        report_date: props.report.report_date,
      }
    // thenで成功した場合の処理をかける
    }).then(response => {
//      console.log('status:'+response.status); // 200
//      console.log('body:'+response.data);     // response body.
      setBackdropOpen(false);
      handleSnackOpen('success', `ID:${response.data.area_id} ${compMessage[mode]}`);
      setButtonDisable(true);
      props.report.nextReport();

    // catchでエラー時の挙動を定義する
    }).catch(err => {
      setBackdropOpen(false);
      var message = null;
      if (err.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(err.response.data);
        if ( err.response.data ) {
          message = err.response.data.detail;
        }
        console.log(err.response.status);      // 例：400
        console.log(err.response.statusText);  // Bad Request
        console.log(err.response.headers);
        if ( err.response.statusText == 'Unauthorized') {
          User.logout();
        }
      } else if (err.request) {
        console.log('request:'+err.request);
      } else {
        console.log('Error:'+err.message);
      }
      console.log(err.config);
      handleSnackOpen('error', message);
    });
  };

  const getArounds = async () => {
    const url = process.env.REACT_APP_API_URL+'/get_arounds'
    const res = await axios( {
      method : 'GET',
      url: url,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+User.getToken(),
      },
      params: {
        lat: props.report.lat,
        lng: props.report.lng,
        distance: distance,
      }
    // thenで成功した場合の処理をかける
    }).then(response => {
//        console.log('status:'+response.status); // 200
//        console.log('body:'+response.data);     // response body.
        const dataList = [];
        response.data.map(row => {
//          console.log(row)
          const arr =  createData(row.area_id, row.geo.x, row.geo.y, row.title, row.summary01, row.rating_status);
          dataList.push(arr);
        });
//         console.log(dataList);
        setArounds(dataList);
    // catchでエラー時の挙動を定義する
    }).catch(err => {
      if (err.response) {
        console.log(err.response.data);
        console.log(err.response.status);      // 例：400
        console.log(err.response.statusText);  // Bad Request
        console.log(err.response.headers);
        if ( err.response.statusText == 'Unauthorized') {
          User.logout();
        }
      } else if (err.request) {
        console.log('request:'+err.request);
      } else {
        console.log('Error:'+err.message);
      }
      console.log(err.config);
    });

  };

  useEffect(() => {
    if ( !props.report || props.report.id == 0 ) {
      history.push('/')
    }

  }, []);

  useEffect(  ()  => {

    // 初期処理
    setArounds([]);
    getArounds();
    setButtonDisable(false);

  }, [props.report.lat, props.report.lng, props.report.id]);

  return (
    <React.Fragment>
      {props.report.id!=0 && (
      <FormControl className={classes.formControl}>
      <Grid
        container
        direction="column"
        spacing={1}
      >
        <Grid
        item
        >
        <Grid
          item
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          spacing={2}
        >

        <Grid item xs={6} md={6} >
          <Typography className={classes.header}  >ID: {props.report.id}</Typography>
          </Grid>
          <Grid item xs={6} md={6} >
          <Typography className={classes.header}  >{dateFormat(props.report.report_date)}</Typography>
          </Grid>
        </Grid>

        <Grid 
          item
          container
          direction="row"
          justify="space-evenly"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={6} md={3} >
            <TextField className={classes.textField} size="small" name="title" label="タイトル" value={props.report.title} onChange={onChangeText}/>
          </Grid>
          <Grid item xs={6} md={3} >
            <TextField className={classes.textField} size="small" name="summary01" label="サマリー" value={props.report.summary01} onChange={onChangeText}/>
          </Grid>
          <Grid item xs={6} md={3} >
            <TextField className={classes.textField} size="small" name="lat" label="緯度" value={props.report.lat} onChange={onChangeText}/>
          </Grid>
          <Grid item xs={6} md={3} >
            <TextField className={classes.textField} size="small" name="lng" label="経度" value={props.report.lng} onChange={onChangeText}/>
          </Grid>
        </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={2}
        >
        <Grid item xs={12}>
        <Card
        className={classes.map}
        >
          <Grid 
            item
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={0}
          >

          <Grid item xs={12}>

            <CardActionArea>
              <CardMedia 
                className={classes.media}
              >
              <MapContainer
                report={props.report}
                setReport={props.setReport}
                arounds={arounds}
              />
              </CardMedia>
            </CardActionArea>
          </Grid>
          </Grid>
        </Card>
        </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          spacing={0}
        >
          <Button
            variant="contained"
            disabled={sendButtonDisabled}
            color="primary"
            className={classes.button}
            onClick={() => handleSendButtonClick('reg')}
            startIcon={<AddLocationIcon />}
          >
            登録
          </Button>
          <Button
            variant="contained"
            disabled={delButtonDisabled}
            color="secondary"
            className={classes.button}
            onClick={() => handleSendButtonClick('del')}
            startIcon={<DeleteIcon />}
          >
            削除
          </Button>
        </Grid>
        </Grid>

        <Dialog
          open={sendOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Typography type='display1'>ID:{props.report.id}</Typography>
            <Typography type='display1'>{props.report.title}</Typography>
            { props.report.summary01 && (
            <Typography type='display1'>[{props.report.summary01}]</Typography>
            )}
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={2}>
              { mode === 'reg' && <AddLocationIcon fontSize="small"/> }
              { mode === 'del' && <DeleteIcon fontSize="small"/> }
              </Grid>
              <Grid item xs={10}>
              {confMessage[mode]}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              いいえ
            </Button>
            <Button onClick={handleSend} color="primary" autoFocus>
              はい
            </Button>
          </DialogActions>
        </Dialog>
        <Backdrop className={classes.backdrop} open={backdropOpen} >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar 
          anchorOrigin={{ vertical:'bottom', horizontal:'center' }}
          open={snackOpen} 
          autoHideDuration={snackDuration} 
          onClose={handleSnackClose} >
          <Alert onClose={handleSnackClose} severity={severity}>
           {snackMessage}
          </Alert>
        </Snackbar>

    </FormControl>
    )}
    </React.Fragment>
  );
}

export default withRouter(ReportChecker);
