import React from 'react';
import {  BrowserRouter, Route, Switch } from 'react-router-dom';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';

import Dashboard from './Dashboard'
import SignIn from './SignIn'
import Auth from './Auth';

const customtheme = createMuiTheme({
  palette: {
    primary:blue,
    secondary:red,
  },
});

function App() {
  return (
    <ThemeProvider theme={customtheme}>
    <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
      <Switch>
        <Route path="/SignIn" exact >
          <SignIn />
        </Route>
        <Auth>
          <Route path="/"  >
            <Dashboard />
          </Route>
        </Auth>
      </Switch>
    </BrowserRouter>
    </ThemeProvider>
  )
}
export default (App);