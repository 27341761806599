import React,  { Component, createContext, useContext, useEffect, useState } from 'react';
import { withRouter, useParams, useHistory } from 'react-router';
import { FormControl } from '@material-ui/core';
import axios from 'axios';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import User from './User';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function SignIn(props) {
  const classes = useStyles();
  const history = useHistory();

  const [user, setUser] = useState({
    email:'',
    password: '',
  });
  const [errMessage, setErrorMessage] = useState(null);

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const login = async() => {

    console.log('email:'+user.email+' password:'+user.password)

    const url = process.env.REACT_APP_API_URL+'/auth';
    await axios.post ( 
      url,
      {
        email: user.email,
        password: user.password,
      }
    // thenで成功した場合の処理をかける
    ).then(response => {
//      console.log(response);
//      console.log('status:'+response.status); // 200
//      console.log('data:'+response.data);     // response body.
      if(response.data.token ){
        console.log("token:"+response.data.token)
        User.login(response.data.token);
      } else {
        throw new Error('login failed');
      }
    // catchでエラー時の挙動を定義する
    }).catch(err => {
      if (err.response) {
        console.log(err.response.data);
        console.log(err.response.status);      // 例：400
        console.log(err.response.statusText);  // Bad Request
        console.log(err.response.headers);
      } else if (err.request) {
        console.log('request:'+err.request);
      } else {
        console.log('Error:'+err.message);
      }
      console.log(err.config);
      throw new Error(err);
    });
  };

  const handleClick =  async() => {

    try {
       await login();
       history.push('/')
    } catch(err) {
      setErrorMessage('メールアドレスかパスワードが違います')
    }
  }

  const onChangeText = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  }

  useEffect(() => {
//    setIsLoggedIn(User.isLoggedIn());

  }, []); 

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          ログイン
        </Typography>
        <FormControl className={classes.form}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Emailアドレス"
            name="email"
            value={user.email}
            onChange={onChangeText}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="パスワード"
            type="password"
            id="password"
            value={user.password}
            onChange={onChangeText}
          />
          { false && (

          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="次サインイン時に覚えておく"
          />
          )
        }
        <Typography type='display1'>{errMessage}</Typography>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => handleClick()}
          >
            ログイン
          </Button>
          { false && (
          <Grid container>
          <Grid item xs>
            <Link href="#" variant="body2">
              パスワードを忘れた
            </Link>
          </Grid>
          <Grid item>
            <Link href="#" variant="body2">
              {"新規にアカウントを作成"}
            </Link>
          </Grid>
        </Grid>

          )
          }
        </FormControl>
      </div>
    </Container>
  );
}

export default (SignIn);