import React, { Component, createContext, useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, BrowserRouter, Switch, useParams, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';

import DashboardIcon from '@material-ui/icons/Dashboard';
import SmokingRoomsIcon from '@material-ui/icons/SmokingRooms';


// Generate Order Data
function createData(id, title, description, summary01, report_date ) {
  return { id, title, description, summary01, report_date };
}

const rows = [
  createData(1, '????', '', '???', '2020/05/01'),
  createData(2, '????', '', '???', '2020/05/01'),
  createData(3, '????', '', '???', '2020/05/01'),
  createData(4, '????', '', '???', '2020/05/01'),
]


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

function ListItems(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const history = useHistory();

    const handleClickNest = () => {
      setOpen(!open);
    };

    const handleClick = (id) => {
      history.push('/Details/'+id)
    }

    
    return (

    <React.Fragment>
      <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
      >
      <div>
        <ListItem button onClick={handleClickNest} >
          <ListItemIcon>
          <Badge badgeContent={4} color="primary">
            <SmokingRoomsIcon />
          </Badge>
          </ListItemIcon>
          <ListItemText primary="PAF List" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>

          {rows && rows.map(row => (
          <ListItem 
            button 
            className={classes.nested}
            onClick={() => handleClick(row.id)}
          >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary={row.id} />
          </ListItem>
          ))}
        </List>
        </Collapse>
      </div>
      </List>
      </React.Fragment>
    );
  }


  export default (ListItems);