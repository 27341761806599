import React,  { Component, createContext, useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, BrowserRouter, Switch, useParams, useHistory  } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DeleteIcon from '@material-ui/icons/Delete';
import KeybordArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SmokingRoomsIcon from '@material-ui/icons/SmokingRooms';

import PlaceIcon from '@material-ui/icons/Place';
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined';
import NotesIcon from '@material-ui/icons/Notes';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/Layers';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import ListItems from './ListItems';

import User from './User';

import CounterReport from './CounterReport';
import CounterArea from './CounterArea';
import ReportChecker from './ReportChecker';

function createDataReports(id, lat, lng, title, description, summary01, reporter, report_date ) {
  return { id, lat, lng, title, description, summary01, reporter, report_date  };
}

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="">
        Bii, Inc.
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
 //   whiteSpace: 'nowrap',
  },
  drawerPaper: {
 //   whiteSpace: 'nowrap',
    overflow: 'auto',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8) + 1,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    marginLeft: -drawerWidth + theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      marginLeft:  -drawerWidth + theme.spacing(8) + 1,
    },

  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  container: {
    flexGrow: 1,
    width:'100%',
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
  counter: {
    width:'100%',
    padding: theme.spacing(2),
  },
  paper: {
    width: '100%',
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
  inlineid: {
    fontSize: 10,
  },
  inlinetitle: {
    fontSize: 14,

  },
  inlinesub: {
    fontSize: 14,
  },

}));

function Dashboard(props) {

  const classes = useStyles();
  const history = useHistory();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [nestOpen, setNestOpen] = useState(true);
  const [countReport, setCountReport] = useState(0);
  const [countArea, setCountArea] = useState(0);
  const [reports, setReports] = useState([]);

  const [isNext, setIsNext] = useState(false);
  const [index, setIndex] = useState(-1);

  const [report, setReport] = useState({
    id:0,
    lat:0,
    lng:0,
    title:'',
    description: '',
    summary01: '',
    reporter: '',
    report_date: '',
    setReport: null,
    nextReport: null,
  });


  const [currtime, setCurrtime] = useState(new Date().getTime());

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  const handleClick = (e, report, index) => {
//    console.log(index);
    setIndex(index);
    setReportEditor(report);
  };

  const setReportEditor = (report) => {
    setReport({
      id: report.id,
      lat: report.lat,
      lng: report.lng,
      title: report.title,
      description: report.description,
      summary01: report.summary01,
      reporter: report.reporter,
      report_date: report.report_date,
      setReport: setReport,
      nextReport: nextReport,
     });
    history.push('/ReportChecker')
  }

  const handleClickDashboard = () => {
    setReports([]);
    setCurrtime(new Date().getTime());
  };

  const handleClickNest = () => {
    setNestOpen(!nestOpen);
  };

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const nextReport = () => {
    setIsNext(true);
  }

  const getReports = async () => {
    const url = process.env.REACT_APP_API_URL+'/get_reports'
    const res = await axios( {
      method : 'GET',
      url: url,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+User.getToken(),
      },

    // thenで成功した場合の処理をかける
    }).then(response => {
//        console.log('status:'+response.status); // 200
//        console.log('body:'+response.data);     // response body.
        const dataList = [];
        response.data.map(row => {
          console.log(row)
          const arr =  createDataReports(row.report_id, row.geo.x, row.geo.y, row.title, row.description, row.summary01, row.reporter, row.report_date);
          dataList.push(arr);
        });
         console.log(dataList);
        setReports(dataList);
        setCountReport(dataList.length);

        if ( isNext ) {
          setIsNext(false)
          if ( dataList && dataList.length > 0) {
            var nextIndex = index;
            if ( dataList.length < index+1) {
              --nextIndex; 
            }
            setIndex(nextIndex);
            setReportEditor(dataList[nextIndex]);
          } else {
            setIndex(-1);
            handleClickDashboard();
          }
        }
    // catchでエラー時の挙動を定義する
    }).catch(err => {
      if (err.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(err.response.data);
        console.log(err.response.status);      // 例：400
        console.log(err.response.statusText);  // Bad Request
        console.log(err.response.headers);
        if ( err.response.statusText == 'Unauthorized') {
          User.logout();
        }
      } else if (err.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log('request:'+err.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error:'+err.message);
      }
      console.log(err.config);
    });
  };

  const getCountReport = async () => {
    const url = process.env.REACT_APP_API_URL+'/get_reports_count'
    const res = await axios( {
      method : 'GET',
      url: url,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+User.getToken(),
      },
    // thenで成功した場合の処理をかける
    }).then(response => {
//        console.log('status:'+response.status); // 200
//        console.log('body:'+response.data);     // response body.
        setCountReport(response.data );
    // catchでエラー時の挙動を定義する
    }).catch(err => {
      if (err.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(err.response.data);
        console.log(err.response.status);      // 例：400
        console.log(err.response.statusText);  // Bad Request
        console.log(err.response.headers);
        if ( err.response.statusText == 'Unauthorized') {
          User.logout();
        }
      } else if (err.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log('request:'+err.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error:'+err.message);
      }
      console.log(err.config);
    });
  };

  const getCountArea = async () => {
    const url = process.env.REACT_APP_API_URL+'/get_areas_count'
    const res = await axios( {
      method : 'GET',
      url: url,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+User.getToken(),
      },
    // thenで成功した場合の処理をかける
    }).then(response => {
//        console.log('status:'+response.status); // 200
//        console.log('body:'+response.data);     // response body.
        setCountArea(response.data );
    // catchでエラー時の挙動を定義する
    }).catch(err => {
      console.log('err:'+err);
      if (err.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(err.response.data);
        console.log(err.response.status);      // 例：400
        console.log(err.response.statusText);  // Bad Request
        console.log(err.response.headers);
        if ( err.response.statusText == 'Unauthorized') {
          User.logout();
        }
      } else if (err.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log('request:'+err.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error:'+err.message);
      }
      console.log(err.config);
    });
  };

  function isIndex(i) {
    if ( i === index) {
      return true
    } else {
      return false
    }
  }

  const handleSignOut = async() => {
    try {
      await User.logout();
      history.push('/')
   } catch(err) {
   }

  }

  useEffect(()  => {
    setIndex(-1)
    history.push('/')
  }, [currtime]);

  useEffect(()  => {
    getReports();
    getCountReport();
    getCountArea();
  }, [currtime, isNext]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar 
      position="absolute" 
      className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, drawerOpen && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            Firebase喫煙所マップ管理画面
          </Typography>
          <Button 
            color="inherit"
            onClick={()=>handleSignOut()}
          >
            LogOut
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !drawerOpen && classes.drawerPaperClose),
        }}
        open={drawerOpen}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />

        <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={classes.root}
        >
          <div>
            <ListItem button onClick={handleClickDashboard} >
              <ListItemIcon>
                <AutorenewIcon />
              </ListItemIcon>
              <ListItemText primary="更新" />
            </ListItem>

            <ListItem button onClick={handleClickNest} >
              <ListItemIcon>
              <Badge badgeContent={countReport} color="primary">
                <NotesIcon />
              </Badge>
              </ListItemIcon>
              <ListItemText primary="報告" />
              {nestOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={nestOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding >

            {reports && reports.map( (report, index) => (
                <ListItem
                  button
                  className={classes.nested}
                  key={report.id}
                  onClick={(event) => handleClick(event, report, index)}
                >
                <ListItemIcon>
                  {isIndex(index) ? <EditLocationIcon />  : <PlaceOutlinedIcon /> }
                </ListItemIcon>
                <ListItemText
                  className={classes.ListItemText}
                  disableTypography
                primary={
                  <Typography
                    className={classes.inlinetitle}
                    color="textPrimary"
                    display="block"
                    type="button"
                    gutterBottom
                  >
                  {report.title}
                  </Typography>
                }
                 />
              </ListItem>
            ))}
            </List>
            </Collapse>

          </div>
        </List>

        <Divider />
      </Drawer>
      <main 
        className={clsx(classes.content, {
        [classes.contentShift]: drawerOpen,
        })}
      >
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>

            <Switch>
              <Route path="/" exact>
                <div className={classes.counter} >
                <Grid
                  container
                  spacing={2}
                  >
                    <Grid item xs={12}>
                      <Paper className={classes.paper}>
                        <CounterReport count={countReport} />
                      </Paper>
                    </Grid>
                    <Grid item xs={12}>
                      <Paper className={classes.paper}>
                        <CounterArea count={countArea} />
                      </Paper>
                    </Grid>
                </Grid>
                </div>
              </Route>
              <Route path="/ReportChecker" exact>
                <ReportChecker
                  report={report}
                  setReport={setReport}
                  nextReport={nextReport}
                />
              </Route>
            </Switch>
        </Container>
      </main>
    </div>

);
}

export default (Dashboard);