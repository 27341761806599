import React, { useEffect, useState, useCallback } from 'react';
import { GoogleMap, LoadScript, InfoWindow, Marker, StreetViewPanorama, OverlayView } from "@react-google-maps/api";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import StreetviewIcon from '@material-ui/icons/Streetview';
import EditIcon from '@material-ui/icons/Edit';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

import 'typeface-roboto';

const editorLink = "/Editor";
const googleMapUrl =  'https://www.google.com/maps?q=';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 200,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  infoWindow: {
    spacing: 0,
  },
  id: {
    fontSize: 12,
  },
  title: {
    fontSize: 14,
  },
  summary01: {
    fontSize: 12,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function MapContainer(props)  {


  const classes = useStyles();
    const containerStyle = {
      width: '100%',
      height: '100%',
      position: "relative",
    }


    const [point, setPoint] = useState(null);
    const [size, setSize] = useState(null);
    const [map, setMap] = useState(null);
    const [marker, setMarker] = useState(null);
    const [infowindow, setInfowindow] = useState(null);
    const [panorama, setPanorama] = useState(null);
    const [toGoogleMap, setToGoogleMap] = useState(null);



    useEffect(() => {
      if (map){
        console.log("zoom:"+map.getZoom())
      }
      if ( marker ) {
        marker.setPosition(new window.google.maps.LatLng( props.report.lat, props.report.lng));
      }
      if ( panorama ) {
        if (panorama && panorama.getVisible() == true ) {
          panorama.setVisible(false)
        }
      }

      setToGoogleMap(googleMapUrl+props.report.lat+','+props.report.lng);

      

    }, [props.report.lat, props.report.lng]); 

    const onLoadMap = useCallback(function callback(map) {
      console.log("onLoadMap");
      const range = 0.0005;
      setPoint(new window.google.maps.Point(16,16));
      setSize(new window.google.maps.Size(32,32));
      const bounds = new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng( props.report.lat-range, props.report.lng-range),
        new window.google.maps.LatLng( props.report.lat+range, props.report.lng+range)
      );
      map.fitBounds(bounds);
      map.setCenter(new window.google.maps.LatLng( props.report.lat, props.report.lng));
      setMap(map)
    }, [])

    const onUnmountMap = useCallback(function callback(map) {
      console.log("onUnmountMap");
      setMap(null)
    }, [])

    const onLoadMarker = useCallback(function callback(marker) {
      console.log("onloadMarker");
      marker.setPosition(new window.google.maps.LatLng( props.report.lat, props.report.lng));
      setMarker(marker);
//      createAroundMaker(props.arounds);
    },[])

    const onUnmountMaker = useCallback(function callback(marker) {
      console.log("onUnmountMarker");
      setMarker(null)
    }, [])

    const onLoadInfoWindow = useCallback(function callback(infowindow) {
      console.log("onloadInfoWindow")
      setInfowindow(infowindow);
    }, [])

    const onMarkerDragEnd = () => {
      props.setReport({
        ...props.report,
        lat: Number(marker.getPosition().lat().toFixed(6)),
        lng: Number(marker.getPosition().lng().toFixed(6)),
      });
    };

    const onMarkerClick = () => {
        console.log("onMarkerClick:")
//        console.log(JSON.stringify(e));
        infowindow.open(map, marker);
    }

    const onCloseInfowindow = useCallback(function callback(infowindow) {
        console.log("onCloseClickInfowindow")
    }, [])

    const onUnmountInfoWindow = useCallback(function callback(infowindow) {
      console.log("onUnmountInfoWindow")
      setInfowindow(null)
    }, [])


    const onLoadAroundMarker = useCallback(function callback(marker) {
//      console.log("onloadAroundMarker"+marker.title);
      window.google.maps.event.addListener(marker, 'click', function(event) {
        new window.google.maps.InfoWindow({
          content: marker.title
        }).open(map, marker);
      });

    },[])

    const onUnmountAroundMaker = useCallback(function callback(marker) {
 //     console.log("onUnmountAroundMaker");
    }, [])

    const setTitle = (around) => {
      const linkto = editorLink+"?id="+around.id;
      var content = "ID:" + around.id + "<br />";
      content = content + "<a target='new' href='" + googleMapUrl + around.lat + "," + around.lng + "' >" + around.title +"</a><br />";
      if ( around.summary01 ) {
        content = content + "【"+ around.summary01+"】<br />";
      }
      content = content + "<a href='"+linkto+"'>詳細…</a>";
      
      return content;
    }



    const onLoadPanorama = useCallback(function callback(panorama) {
      console.log("onLoadPanorama");
      setPanorama(panorama);
    }, [])
    const onUnmountPanorama = useCallback(function callback(panorama) {
      console.log("onUnmountPanorama");
      setPanorama(null);
    }, [])

    const handleStreetviewChange = () => {
      if ( panorama) {
        panorama.setVisible(true)
      }
    };


    return (
      <React.Fragment>

      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
      >

      <GoogleMap
        mapContainerStyle={containerStyle}
        center={{ lat: props.report.lat, lng: props.report.lng}}
        onLoad={onLoadMap}
        onUnmount={onUnmountMap}
      >


      <StreetViewPanorama
        position={{ lat: props.report.lat, lng: props.report.lng}}
        onLoad={onLoadPanorama}
        onUnmount={onUnmountPanorama}
      />

      <Marker
          key={props.report.id}
          title={props.report.title}
          position={{ lat: props.report.lat, lng: props.report.lng}}
          draggable={true}
          onDragEnd={onMarkerDragEnd}
          onClick={()=>onMarkerClick()}
          onLoad={onLoadMarker}
          onUnmount={onUnmountMaker}
          zIndex={100}
          icon={{
            url: process.env.PUBLIC_URL+"/inicon_gl.svg",
            anchor: point,
            scaledSize: size,
          }}
          >
          <InfoWindow
            onLoad={onLoadInfoWindow}
            onUnmount={onUnmountInfoWindow}
            onCloseClick={onCloseInfowindow}
          >
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid
              container
              alignItems="flex-start"
              >
                <Grid item xs={12}>
                  ID:{props.report.id}
                </Grid>
                <Grid item xs={12}>
                <Link href={toGoogleMap} target="new" >{props.report.title}</Link>
                </Grid>
                {props.report.summary01 && (
                <Grid item xs={12}>
                【{props.report.summary01}】
                </Grid>
                )}
              </Grid>
              <Grid item xs={12}>
                  <IconButton aria-label="streetview">
                    <StreetviewIcon 
                    fontSize="small" 
                    onClick={handleStreetviewChange}
                    />
                  </IconButton>
              </Grid>
            </Grid>
          </InfoWindow>
        </Marker>

        { props.arounds.map((around, index) => (
            <Marker
              key={around.id}
              title={setTitle(around)}
              position={{ lat: around.lat, lng: around.lng }}
              draggable={false}
              zIndex={10}
              onLoad={onLoadAroundMarker}
              onUnmount={onUnmountAroundMaker}
              label={{
                text: around.title,
                color: "#555555",
                backgroundColor: "#000000",
                textShadow: "0 0 3px #000000",
              }}
              icon={{
                url: process.env.PUBLIC_URL+"/inicon_"+around.rating+".svg",
                anchor: point,
                scaledSize: size,
              }}
            >
            </Marker>
        ))}

      </GoogleMap>
      </LoadScript>

      </React.Fragment>
    );
}

export default React.memo(MapContainer);